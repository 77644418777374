@font-face {
    font-family: 'QuebecSerialLightRegular';
    src: url('quebec_serial-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

*, ::after, ::before {
	box-sizing: border-box;
}

footer, header, nav, section {
	display: block;
}

html {
    -webkit-text-size-adjust: 100%;
    height: 100%;
}

body { 
    font: normal 400 1rem/1.25 QuebecSerialLightRegular,sans-serif;
    letter-spacing: .05em;
    text-align: center;
    color: #282828;
    background-color: #fbfeff;
    margin: 0;
    height: 100%; 
    /*overflow: hidden;*/
}

/* .container */
.con {
    display: flex; 
    flex-direction: column; 
    min-height: 100%;
    overflow:auto;
}

.cc {
    position: fixed;
    bottom: 0;
    right: 0;
    font-size: 14px;
    max-width: 320px;
    background: #f1f1f1;
    text-align: left;
    padding: 16px;
    border-radius: 6px;
    transition: all .15s ease-in-out;
    z-index: 1;
}

.cc.hide {
    transform: translate(100%, 0);
}

.cbts {
    margin: 6px 0 0 0;
    text-align: center;
}

/* .header */
.hed {
    background: rgba(20,20,20,.95);
	box-shadow: 0 10px 20px rgba(0,0,0,.2);
}

/* logo */
#lgo {
    display: block;
    text-align: left;
    color: #fff;
    font-style: italic;
    text-decoration: none;
    font-size: 0.9375rem;
    height: 3.5625rem;
    background: no-repeat center left / contain;
    padding: 1.1875rem 0 0 1.1875rem;
}

/* .header-logo */
.hd-l {
    display: block;
    height: 56px;
    width: auto;
    margin: 0 0 1px;
}

/* .max-width */
.mxw {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

/* .gradient */
.grd {
	background: #6bc7ea;
	background: linear-gradient(to right,#6bc7ea 0,#e94e91 20%,#ffd54e 40%,#63539e 60%,#78b72a 80%,#6bc7ea 100%);
	background-size: 250% 100%;
	height: 2px;
    animation: a7 30s linear infinite;
    width: 100%;
}

/* .hero-outer */
.hr-o {
    flex:1; 
    padding:30px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    max-height: 500px;
}

/* .hero-inner */
.hr-i {
    width: 1px; 
    display:block; 
    transform-origin: bottom right;
}

.hr-i.on {
    animation: a1 1s cubic-bezier(0.18, 0.89, 0.46, 1.07) forwards;
}

.hide { 
    opacity: 0;
}

/* .intro */
.int {
    padding: 0 30px 30px;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}

h1 {
    font-weight: normal;
    color: #22a0db;
    font-size: 32px;
    line-height: 1.42em;
    letter-spacing: 0.025em;
    margin: 0 0 32px;    
}

h3 {
    font-weight: normal;
    font-size: 24px;
    line-height: 1.42em;
    letter-spacing: 0.038em;
    margin: 0 0 24px;
}

h4 {
    color: rgba(251,254,255,0.6);
    margin-bottom: 10px;
    height: 17px;
    overflow: hidden;
    font-size: 14px;    
    letter-spacing: 0.188em;
    font-weight: normal;
    margin: 0 0 10px;
}

h4.out {
    animation: a3 500ms cubic-bezier(0.55, 0.06, 0.68, 0.19) forwards;
}

h4.in {
    animation: a4 500ms cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}

p, ul { margin: 24px 0; }

.lrg {
    font-size: 20px;
    line-height: 34px;
}

p:last-child {
    margin-bottom: 0;
}

.btn {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.188em;
    color: #fbfeff;
    display: inline-block;
    border-radius: 4px;
    box-shadow: none;
    padding: 9px 16px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: all .15s ease-in-out;
    outline: none;
    border: none;
    text-decoration: none;
}

.pri {
    background: #22a0db;
}

.pri:hover {
    background: #6bc7ea;
}

.pri:active {
    background: #0062cc;
}

.lte {
    background: #DADADA;
    color: #282828;
    margin: 0 6px 0 0;
}

.lte:hover {
    background: #CACACA;
}

.flx {
    flex: 1;
    margin: 0 30px;
}

.bc1 {
    color: #2da0da;
}
.bc2 {
    color: #d71957
}
.bc3 {
    color: #e69700;
}
.bc4 {
    color: #30276a;
}
.bc5 {
    color: #368033;
}
.bc6 {
    color: #2ba68b;
}
.bc7 {
    color: #d64545;
}
.bc8 {
    color: #00609c;
    margin: 24px 0;
}

.wbg {
    background: #fbfeff;
}

.wbg:hover {
    background: #fff;
}

.wbg:active {
    background: #e6e9ea;
}

.sec {
    color: #fbfeff;
    background: rgba(251,254,255,0.2);
}

.sec:hover {
    background: rgba(251,254,255,0.4);
}

.sec:active {
    background: rgba(251,254,255,0.1);
}

/* .svg-background */
.sbg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/* .svg-dot */
.dot {
    animation: a2 2s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}

/* .progress */
.prg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px 30px 0;
    opacity: 0;
    transition: opacity 1s;
}

/* .progress-bar */
.pbar {
    display:flex;
}

.pbar > div {
    height: 3px;
    transition: flex 1s ease-in-out;
}

.pbar > div:first-child {
    background: rgba(251,254,255,0.8);
    flex: 0;
}

.pbar > div:last-child {
    background: rgba(20,20,20,0.1);
    flex: 7;
}

/* .stage */
.stg {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    color: #fbfeff;
    overflow: auto;
}

/* .stage-inner */
.sti {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    min-height: 100%;
}

ul {
    padding-left: 44px;
    list-style-type: none;
    text-align: left;
}

li {
    margin-bottom: 8px;
    position: relative;
}

li::before {
    content: "";
    position: absolute;
    top: 8px;
    left: -24px;
    width: 4px;
    height: 4px;
    background: #fbfeff;
    border-radius: 2px;
}

.out .t0,
.out .t2,
.out .t6,
.out .t8,
.out .t10 {
    opacity: 0;
    transform: translate(0, -20px);
}

/* .transition-0ms */
.t0 { transition: all 1s; }
/* .transition-200ms */
.t2 { transition: all 1s 200ms; }
/* .transition-600ms */
.t6 { transition: all 1s 600ms; }
/* .transition-800ms */
.t8 { transition: all 1s 800ms; }
/* .transition-1000ms */
.t10 { transition: all 1s 1000ms; }

.stg-7.out {
    opacity: 0;
}

/* .transition-0ms */
.t0 { transition: all 1s; }

.dfx {
    display: flex;
}

.fx1 {
    flex: 1;
    position: relative;
    min-height: 100px;
    max-height: 320px;
}

.imn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.irn {
    background: no-repeat center center / contain;
    transition: transform 1s 400ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.out .irn {
    transform: scale(0);
}

.iic {
    background: no-repeat center center / contain;
    transition: transform 1s 600ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.out .iic {
    transform: scale(0);
}

form { 
    text-align: left;
}

.fg {
    margin: 24px 0;
}

.fg > small {
    font-size: 12px;
    display: inline-block;
    margin: 6px 20px;
}

.tin {
    display: block;
    border: none;
    background: rgba(251,254,255,0.2);
    width: 100%;
    color: #fbfeff;
    font: normal 400 1rem/1.25 QuebecSerialLightRegular,sans-serif;
    letter-spacing: .05em;
    padding: 10px 20px;
    border-radius: 20px;
    outline: none;
    resize: none;
}

.tin::placeholder {
    color: rgba(251,254,255,0.4);
}

.tin:focus {
    box-shadow: inset 0px 0px 6px 0px rgba(251,254,255, 0.5);
}

.lb {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    margin: 6px 20px;
    display: block;
}

.rad { 
    display: inline-block; 
}

.rad label {
	cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    position: relative;
    padding: 10px 0 10px 56px;
    margin-right: 32px;
}

.rad label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: rgba(251,254,255,0.2);
    transition: all 0.5s;
}

.rad input {
    display: none;
}

.rad input:checked + label::before {
    box-shadow: inset 0px 0px 6px 0px rgba(251, 254, 255, 0.5);
}

.rad input:checked + label::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    margin: 14px;
    border-radius: 20px;
    background: #fbfeff;
    animation: a2 0.5s cubic-bezier(.22,.61,.36,1);
}

.spn {
    max-width: 200px;
    width: 50%;
    display: block;
    margin: 30px auto;
    transition: all 1s;
}

.spn.out {
    opacity: 0;
}

.spo {
    transform-origin:center;
    animation: a6 1s linear infinite;
}

.spi {
    fill: none;
    stroke: #fbfeff;
    stroke-width: 30px;
    stroke-dasharray: 1611 180;
    stroke-dashoffset: 0;
    animation: a5 3s ease-in-out infinite alternate;
}

.mk {
    fill: #00609c;
    transition: all 750ms 500ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transform: scale(1) translate(0, 0);
    transform-origin: center;
}

.pd .mk {
    transform: scale(0) translate(0, 600px);
}

.dt {
    fill: #fbfeff;
    transform: scale(1);
    transform-origin: center;
    transition: all 500ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

.pd .dt {
    transform: scale(0);
}

.un {
    padding: 30px;
}

/* Animations */
@keyframes a1 {
    0% {
        opacity: 0;
        transform: translate(-200px, 0px);
    }
    100% {
        opacity: 1;
        transform: translate(0px,0px);
    }
}

@keyframes a2 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes a3 {
    0% {
      opacity: 1;
      transform: translate(0, 0);
    }
    100% {
      opacity: 0;
      transform: translate(-200px, 0);
    }
}

@keyframes a4 {
    0% {
        opacity: 0;
        transform: translate(200px, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes a5 {
    0% {
        stroke-dasharray: 1611 180;
    }
    100% {
        stroke-dasharray: 180 1611;
    }
}

@keyframes a6 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes a7 {
    0% {
        background-position:0 0
    }
    100% {
        background-position:166.666% 0
    }
}